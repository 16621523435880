<template>
  <div :id="id" :class="['m-product-list-display', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']">
    <div class="m-product-list-display-content">
      <VisualItem v-if="isPC && mainTitle && mainTitle.value" :visualKeys="mainTitle.visualKeys" @visual="handleVisual" class-name="m-main-title">
        <p class="content">
          {{ mainTitle.value }}
        </p>
      </VisualItem>
      <VisualItem v-if="!isPC && mainTitleH5 && mainTitleH5.value" :visualKeys="mainTitleH5.visualKeys" @visual="handleVisual" class-name="m-main-title">
        <p class="content">
          {{ mainTitleH5.value }}
        </p>
      </VisualItem>
      <VisualItem v-if="secondTitle && secondTitle.value" :visualKeys="secondTitle.visualKeys" @visual="handleVisual" class-name="m-second-title">
        <p class="content">
          {{ secondTitle.value }}
        </p>
      </VisualItem>
      <div class="u-product-list">
        <VisualItem :visualKeys="list.visualKeys" @visual="handleVisual">
          <div
            v-for="(item, index) in list.value"
            :key="`product-${index}`"
            class="m-product"
          >
            <div v-if="isPC" class="u-img">
              <VisualItem :visualKeys="[item.img.visualKeys, item.img_2x.visualKeys]" @visual="handleVisual">
                <CMSImage
                  :img="item.img.value"
                  :img2x="item.img_2x.value"
                />
              </VisualItem>
            </div>
            <div v-else class="u-img">
              <VisualItem :visualKeys="[item.img_2x_h5.visualKeys, item.img_3x_h5.visualKeys]" @visual="handleVisual">
                <CMSImage
                  :img="item.img_2x_h5.value"
                  :img2x="item.img_3x_h5.value"
                />
              </VisualItem>
            </div>
            <div class="u-info">
              <p class="title">
                <!-- visualKeys 组合的key 用于获取节点数据、更新节点等操作 -->
                <VisualItem :visualKeys="item.title.visualKeys" @visual="handleVisual">
                  {{ item.title.value }}
                </VisualItem>
              </p>
              <p class="desc1">
                <VisualItem :visualKeys="item.desc1.visualKeys" @visual="handleVisual">
                  {{ item.desc1.value }}
                </VisualItem>
              </p>
              <p class="desc2">
                <VisualItem :visualKeys="item.desc2.visualKeys" @visual="handleVisual">
                  {{ item.desc2.value }}
                </VisualItem>
              </p>
            </div>
          </div>
        </VisualItem>
      </div>
      <VisualItem :visualKeys="[moreLink.visualKeys, moreLinkText.visualKeys]" @visual="handleVisual" class-name="more-link">
        <CMSLink :link="moreLink.value" class-name="link">
          {{ moreLinkText.value }}
        </CMSLink>
      </VisualItem>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import VisualItem from '~/components/common/VisualItem'
import CMSLink from '~/components/common/Link'
import CMSImage from '~/components/common/Image'

const componentKey = 'ProductListDisplay'

export default {
  components: {
    VisualItem,
    CMSImage,
    CMSLink
  },
  props: {
    id: {
      type: [String, Number],
      default: componentKey
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data(content) {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(_.cloneDeep(config), componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    return {
      ...data
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  mounted() {
    console.log(this.moreLink)
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-product-list-display {
  &.z-web {
    padding: 45px 0 60px;
    background: #fff;

    .m-product-list-display-content {
      width: 1200px;
      margin: 0 auto;

      /* 主副标题添加长度限制 */
      .content {
        @mixin ellipsis;
      }

      .u-product-list {
        margin-top: 60px;

        .m-product {
          min-width: 320px;
          display: inline-block;
          margin-right: 95px;

          &:nth-child(3n) {
            margin-right: 0;
          }

          &:nth-child(-n+3) {
            margin-bottom: 90px;
          }

          .u-img {
            float: left;
            margin-right: 40px;
            max-width: 90px;
          }

          .u-info {
            .title {
              font-size: 20px;
              line-height: 24px;
              color: $mainTitleColor;
              margin-bottom: 16px;
            }

            .desc1,
            .desc2 {
              font-size: 14px;
              line-height: 24px;
              color: #6f7993;

              div {
                @mixin ellipsis;
              }
            }
          }
        }
      }

      .more-link {
        margin-top: 50px;
        text-align: center;

        .link {
          font-size: 14px;
          line-height: 20px;
          color: #0057ff;
        }
      }
    }
  }

  &.z-phone {
    padding: pxtorem(52) pxtorem(60);

    .m-second-title {
      font-size: pxtorem(28);
      line-height: pxtorem(48);
      color: #6f7993;
    }

    .u-product-list {
      margin-top: pxtorem(80);

      .m-product {
        margin-bottom: pxtorem(60);

        &:last-child {
          margin-bottom: 0;
        }
      }

      .u-img {
        max-width: pxtorem(120);
        vertical-align: top;
        float: left;
        margin-right: pxtorem(60);
      }

      .u-info {
        .title {
          font-size: pxtorem(32);
          line-height: pxtorem(48);
          color: $mainTitleColor;
          margin-bottom: pxtorem(12);
        }

        .desc1,
        .desc2 {
          font-size: pxtorem(28);
          line-height: pxtorem(48);
          color: #6f7993;

          div {
            @mixin ellipsis;
          }
        }
      }
    }
  }
}
</style>
